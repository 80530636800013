<template>
	<div>
		<div class="updata">
			<div class="home_left">
				<div class="user_home">文件上传</div>
				<div class="box">
					<div class="add_name">创建专辑</div>
					<div class="add_up_box">
						<div class="add_up_span" @click="btnAdd(1)">+</div>
						<div class="add_up_title">如果你没有专辑请你点击创建</div>
					</div>
				</div>
				<albumList :albumList="albumList" @albumIdIndex="albumIdIndex"></albumList>
			</div>
		</div>
		<!-- add封面 -->
		<addAlbumCover :addAlbum="addAlbum"></addAlbumCover>
		<!-- add专辑 list-->
		<addAlbumList :addAlbumList="addAlbumList"></addAlbumList>
		<!-- 查看list -->
		<addAlbumLook :addAlbumLook="addAlbumLook"></addAlbumLook>
	</div>
</template>

<script>
import selectColor from './selectColor.vue'
import addAlbumCover from './addAlbum.vue'
import addAlbumList from './addAlbumMusic.vue'
import addAlbumLook from './addAlbumLook.vue'
import albumList from './albumList.vue'
import { timeDay } from '../../../components/js/index'
import api from '../../../api/album'


export default {
	name: 'index',
	data() {
		return {
			tabIndex: 0, // 专辑名称
			albumList: {
				list: [],
				btnAdd: this.btnAdd,  //

			},
			// 创建 专辑
			addAlbum: {
				isHide: false,
				albumName: "",
				albumAuthor: "",
				albumTag: "",
				albumDesc: "",
				cancelCallback: this.cancelCallback,  //
				determineCallback: this.addAlbumCallback
			},
			addAlbumList: {
				isHide: false,
				songName: "",
				songDesc: "",
				songAuthor: "",
				songTag: "",
				albumId:"",
				cancelCallback: this.cancelCallback,  //
				determineCallback: this.addAlbumListCallback
			},

			addAlbumLook: {
				isHide: false,
				musicList: {},
				cancelCallback: this.cancelCallback,  //
				determineCallback: this.addAlbumLookCallback
			},
		}
	},
	components: {
		selectColor,
		addAlbumCover,
		addAlbumList,
		addAlbumLook,
		albumList
	},
	mounted() {
		this.listFuns()
	},
	methods: {
		// 分类
		btnAlbumList(item) {

		},
		// 创建专辑
		btnAdd(val,item='') {
			if (val == 1) {
				this.addAlbum.isHide = true
				console.log(val, "add album");
			} else if (val == 2) {
				this.addAlbumList.isHide = true
				console.log(item, "11 look album");
			} else if (val == 3) {
				this.addAlbumLook.isHide = true
				console.log(val,item.id, "look albumlist");
				this.lookFun(item.id)
			}else {
				alert("暂时不能删除")
			}
		},
		// add 专辑 回调
		addAlbumCallback(obj) {
			console.log(obj,"add 成功回调");
			this.listFuns()
		},
		listFuns() {
			var obj ={
				pageSize:50
			}
			api.albumList(obj).then((res) => {
				if (res.data.code == 200) {
					console.log(res.data.result, 9999)
					this.albumList.list = res.data.result.list
				}
			})
		},
		
		// 专辑 list  点击 list  
		albumIdIndex(item) {
			console.log(item,item.id,"专辑 list  点击 list ");
			this.$emit("addAlbumList",item)
			this.addAlbumList.albumId=item.id
			// this.lookFun(item.id)
		},
		// 点击查看 专辑list
		lookFun(data){
			var obj ={
				song_album_id:data
			}
            api.songsList(obj).then((res) => {
				if (res.data.code == 200) {
					console.log(res.data.result,"点击查看 专辑list");
					this.addAlbumLook.musicList =res.data.result
				}
			})
        },


		//  添加 创建 专辑 ajax
		gitAlbumAdd() {
			timeDay(new Date().getTime())
		},
		// 点击获取 专辑 id
		btnAlbumItem(id) {
			console.log(id.albumId)
			this.albumListId = id.albumId
		},


	}
}
</script>
<style scoped>
.home_left {
	padding-top: 10px;
	min-height: 700px;
	padding-left: 20px;
	overflow: hidden;
}

.box {
	float: left;
}

.boxs.bf {
	background: #fb7299;
}

.boxs:hover {
	border: 1px solid #fb7299;
}



.user_home {
	font-size: 32px;
	margin-bottom: 30px;
	padding-bottom: 12px;
	box-sizing: border-box;
	border-bottom: 1px solid rgba(0, 0, 0, .07);
	;
}

.add_name {
	margin-top: 12px;
	font-size: 14px;
}

.add_name_span {
	font-size: 10px;
	margin-left: 4px;
	color: #999;
}

.add_name_color {
	color: #555;
	font-size: 10px;
}

.add_up_box {
	margin-right: 14px;
	width: 140px;
	min-height: 180px;
	border: 1px solid #dbdbdb;
	border-radius: 4px;
	text-align: center;
	margin-top: 4px;
	padding: 2px;
	box-sizing: border-box;
	cursor: pointer;
	position: relative;
}

.add_up_span {
	width: 130px;
	height: 130px;
	background: #e9ecea;
	font-size: 40px;
	color: #dbdbdb;
	line-height: 130px;
	margin: 2px auto;
	border-radius: 4px;
}

.add_up_title {
	font-size: 12px;
	color: #dc241a;
	text-align: center;
	margin-top: 5px;
}
.updata{
	padding-bottom: 68px;
}
</style>
