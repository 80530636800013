<template>
    <div>
        <div class="updata">
            <div class="box" v-for="(item, index) in all.list" :key="index"
                @click.stop="btnAlbumItem(item), tabIndex = index">
                <div class="add_name">专辑时间<span class="add_name_span">{{ item.album_issue_date }}</span></div>
                <div class="add_up_box boxs" :class="tabIndex == index ? 'bf' : ''" :style="{background:  tabIndex == index ? rgba(item.album_colors):''}">
                    <div class="add_up_span">
                        <imgIng v-if="item&&item.album_cover" class="box_up_img" :src=" 'http://82.157.140.72/test'+(item&&item.album_cover)" alt=""></imgIng>
                    </div>
                    <div class="add_up_nub" >
                        <div>专辑名字 <span class="add_name_color">{{ item.album_name }}</span></div>
                        <div>专辑标签 <span class="add_name_color">{{ item.album_colors }}</span></div>
                        <div v-if='tabIndex == index'>
                            <div class="bottom"  @click="all.btnAdd(2,item)">添加音乐</div>
                            <div class="bottom look" @click="all.btnAdd(3,item)">查看音乐</div>
                            <div class="bottom delete" :style="{background:item.album_colors}" @click="all.btnAdd(4,item)">✕</div>
                        </div>
                        <div v-else>
                            <div class="bottom aleat">点击选择上传歌曲</div>
                        </div>
                    </div>
                    
                </div>
            </div>
            <div style="clear: both;"></div>
        </div>
    </div>
</template>

<script>
import imgIng from '../../../components/common/imgIng.vue'
export default {
    name: 'index',
    props: {
        albumList: {
            type: Object,
            default: () => {
                return {
                    list: [],
                    id:"",
                    btnAdd: () => { },  //
                }
            }
        },
    },
    data() {
        return {
            tabIndex: 0,
            list: "",
            id:"",

        }
    },
    computed: {
        all() {
            let Obj = this.albumList
            if (!Obj.list) {
                Obj.list = this.list
            }
            if (!Obj.id) {
                Obj.id = this.id
            }
            if (!Obj.btnAdd) {
                Obj.btnAdd = this.btnAdd
            }
            return Obj
        }
    },
    components: {
        imgIng
    },
    mounted() {
    },
    methods: {
        // 点击获取 专辑 id
        btnAlbumItem(item) {
           this.$emit("albumIdIndex", item)
        },
        // 预处理 先留着
        rgba(v){
           return v 
        }
    
    }
}
</script>
<style scoped>
.box {
    float: left;
}

.boxs.bf {
    background: #fb7299;
}

.boxs:hover {
    border: 1px solid #fb7299;
}

.add_album_title {
    text-align: center;
    font-size: 22px;
    margin-bottom: 26px;
}

.user_home {
    font-size: 32px;
    margin-bottom: 30px;
    padding-bottom: 12px;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(0, 0, 0, .07);
    ;
}

.add_name {
    margin-top: 12px;
    font-size: 14px;
}

.add_name_span {
    font-size: 10px;
    margin-left: 4px;
    color: #999;
}

.add_name_color {
    color: #555;
    font-size: 10px;
}

.add_up_box {
    margin-right: 14px;
    width: 144px;
    min-height: 180px;
    border: 1px solid #dbdbdb;
    border-radius: 4px;
    text-align: center;
    margin-top: 4px;
    padding: 2px;
    box-sizing: border-box;
    cursor: pointer;
    position: relative;
}

.add_up_span {
    width: 130px;
    height: 130px;
    background: #e9ecea;
    font-size: 40px;
    color: #dbdbdb;
    line-height: 130px;
    margin: 2px auto;
    border-radius: 4px;
}

.box_up_img {
    width: 100%;
    height: 100%;
}

.add_up_nub {
    font-size: 10px;
    text-align: left;

}

.add_album {
    height: 100%;
    background: rgba(0, 0, 0, .3);
    position: fixed;
    top: 60px;
    left: 210px;
    bottom: 0;
    right: 0;
    z-index: 1;
}

.add_album_box {
    width: 800px;
    min-height: 200px;
    background: #fff;
    margin: 30px auto;
    padding: 12px;
    box-sizing: border-box;
    border-radius: 6px;
    position: relative;
    padding-bottom: 120px;
}

.add_album_son {
    width: 100%;
    max-height: 700px;
    overflow: scroll;
}

.bottom {
    width: 59px;
    background: red;
    display: inline-block;
    margin-right: 8px;
    padding: 2px 5px;
    box-sizing: border-box;
    color: #fff;
    font-size: 10px;
    border-radius: 4px;
}

.look {
    background: gold;
}
.delete{
    background: #100b0b;
    position: absolute;
    top: -8px;
    right: -15px;
    z-index: 1;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.aleat {
    width: 132px;
    background: #100b0b;
}
</style>
