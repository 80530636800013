<template>
  <div id='outDiv' style="position: relative">
    <div class="ov">
      <div class="cover" id='cover'></div>
      <div class="nub">{{ rgbStr }}</div>
    </div>
    <canvas class="box" id="show"></canvas>
    <!-- <em id="cur"></em> -->
  </div>
</template>
   
<script>
export default {
  name: "CanvasColor2",
  data() {
    return {
      canvas: null,
      context: null,
      paramColor: '',
      viewWidth: 0,
      viewHeight: 0,
      rgbStr: ""
    }
  },
  props: {
    data: String,
    canvasWidth: Number,
    canvasHeight: Number,
  },
  created() {
    this.viewWidth = this.canvasWidth;
    this.viewHeight = this.canvasHeight;
  },
  mounted() {
    this.getParamColor('rgb(0,0,1)'); //默认颜色
    this.initData();
    this.getDotList();
  },
  methods: {
    async getDotList() {
      this.canvas = document.getElementById("show");
      this.context = this.canvas.getContext("2d");
      // console.log('getDotList', this.canvas.offsetLeft);
      // 获取整个 canvas 的像素信息
      var imgData = this.context.getImageData(0, 0, this.canvas.width + this.canvas.offsetLeft, this.canvas.height + this.canvas.offsetTop);
      // 清空数组
      var dotList = [];
      // 最后实现的效果每个点之间有一定的距离，gap 就是控制这个距离的
      var gap = 1;
      // 通过 width 和 height 遍历 imgData 对象，每隔 gap 个点取一次像素，找到红色的像素，
      // 每找到一个红色点，就创建一个 Dot 对象，并添加到 dotList 数组中
      for (var x = 0; x < imgData.width; x += gap) {
        for (var y = 0; y < imgData.height; y += gap) {
          var i = (y * imgData.width + x) * 4;
          // console.log('getDotList',i);
          // 判断像素点是不是红色
          if (imgData.data[i] == this.paramColor[0] && imgData.data[i + 1] == this.paramColor[1] && imgData.data[i + 2] == this.paramColor[2]) {
            var dot = { x, y };
            dotList.push(dot);
          }
        }
      }

      if (dotList && dotList.length != 0) {
        var cur = document.getElementById('cur');
        cur.style.left = (dotList[0].x + this.canvas.offsetLef) + 'px';
        cur.style.top = (dotList[0].y + this.canvas.offsetTop) + 'px';

      }
      // console.log('dot', dotList);
      return dotList;
    },
    initData() {
      var that = this
      var show = document.getElementById("show");
      // show.setAttribute("width", this.canvasWidth);
      // show.setAttribute("height", this.canvasHeight);

      show.setAttribute("width", '200%');
      show.setAttribute("height", '200%');
      // var cover = document.getElementById("cover");
      var cur = document.getElementById("cur");
      if (show.getContext) {//首先判断getcontext方法是否存在，这是一个良好的习惯
        var context = show.getContext('2d');
        var gradientBar = context.createLinearGradient(0, show.height, show.width, show.height);//创建渐变，前两个参数是渐变开始的横纵坐标，后两个参数是渐变结束的横纵坐标

        gradientBar.addColorStop(0, '#ff0000');
        gradientBar.addColorStop(1 / 6, '#ff00ff');
        gradientBar.addColorStop(2 / 6, '#0000ff');
        gradientBar.addColorStop(3 / 6, '#00ffff');
        gradientBar.addColorStop(4 / 6, '#00ff00');
        gradientBar.addColorStop(5 / 6, '#ffff00');
        gradientBar.addColorStop(1, '#ff0000');

        context.fillStyle = gradientBar;
        context.fillRect(0, 0, show.width, show.width);

        //白色透明黑色，明暗度实现
        var lightToDark = context.createLinearGradient(0, 0, 0, show.width);
        lightToDark.addColorStop(0, "#fff");
        lightToDark.addColorStop(1 / 2, 'rgba(255,255,255,0)');
        lightToDark.addColorStop(1, '#000');

        context.fillStyle = lightToDark;
        context.fillRect(0, 0, show.width, show.width);

        show.addEventListener('click', function (e) {
          var ePos = {
            x: e.layerX || e.offsetX,
            y: e.layerY || e.offsetY
          }
          //前两个参数为鼠标的位置，后娘个参数为canvas的宽高
          var imgData = context.getImageData(ePos.x, ePos.y, show.width, show.height).data;
          //可以通过下面的方法获得当前的rgb值
          var red = imgData[0];
          var green = imgData[1];
          var blue = imgData[2];
          var rgbStr = "rgb(" + red + "," + green + ',' + blue + ")";
          that.rgbStr = rgbStr
          // console.log(rgbStr);
          // that.$emit("rgbStr", rgbStr)
       


          var rgb = rgbStr.split(',');
          var r = parseInt(rgb[0]);
          var g = parseInt(rgb[1]);
          var b = parseInt(rgb[2]);
          var hex = "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
          // console.log(hex,"11111111");
         

          that.$emit("rgbStr", hex)



          var cover = document.getElementById("cover");
          cover.style.backgroundColor = rgbStr;
          // var cur = document.getElementById("cur");
          var outDiv = document.getElementById('outDiv');
          // console.log('outDiv', outDiv.offsetTop + ',' + outDiv.offsetHeight);
          var pos = {
            x: e.clientX,
            y: e.clientY - outDiv.offsetTop//当该组件整体为相对位置时，y轴的坐标是当前点的位置-最外层距离顶点的高度
          }
          // cur.style.left = pos.x + 'px';
          // cur.style.top = pos.y + 'px';
          // console.log(pos.x,33);
          // cur.style.left = (pos.x -200) + 'px';
          // cur.style.top = (pos.y - 90) + 'px';
          // console.log('onmousemove', cur.style.left);
        });
      }
    },
    getParamColor(color) {
      let param = color;
      // console.log('getParamColor', param.length)
      param = param.substring(4, param.length - 1);
      // console.log('getParamColor', param)
      this.paramColor = param.split(',');
      this.getDotList();
      var cover = document.getElementById("cover");
      cover.style.backgroundColor = color;
    }
  }
}
</script>
   
<style scoped>
.box {
  position: relative;
  background-color: pink;
  border: 1px solid #999;
  
}

/* 选择颜色的小方格 */
#cur {
  width: 13px;
  height: 13px;
  outline: 2px solid #ffffff;
  position: absolute;
  border-radius: 13px;
  cursor: pointer;
}

.nub {
  margin-top: 4px;
}

.ov {
  display: flex;
  justify-content: center;
  align-items: center;

}

.nub {
  flex: 1;

}

#cover {
  margin-right: 3px;
  width: 15px;
  height: 15px;
  background-color: antiquewhite;
  /* cover作为遮罩挡住了canvas，使用这个可以穿透遮罩，点击到下面的元素 */
  border: 1px solid #999;
}
</style>