<template>
    <div>
        <div class="down" @click.self="isHide=true">
            {{title}}
            <div class="list" v-if="isHide">
                <div class="li" 
                    v-for="item,index in tabbar" 
                    :key="index"  
                    @click="btnDown(item);
                    title=item.category_name"
                  >
                    {{item.category_name}}
                </div>
            </div>
        </div>


    </div>


</template>
  
<script>


import api from '../../../api/index'
export default {
    name: '',
    computed: {


    },

    data() {
        return {
            isHide:false,
            title:"请选择",
            tabbar:"",
        }
    },
    components: {

    },
    watch: {


    },
    created() {

    },

    mounted() {
        this.catesFun()
    },
    methods: {

		catesFun() {
			api.cates().then((res) => {
				if (res.data.code == 200) {
					this.tabbar= res.data.result
                    var arr = []
                    res.data.result.forEach((item,index) => {
                        item.list.forEach((son,index) => {
                            arr.push(son) 
                        });
                       
                    });
                    this.tabbar =arr
				}
			})
		},
        btnDown(item){
            this.isHide=false
            this.$emit("selectDown",item)
        }
	


	},
}
</script>
  
  
<style scoped lang="scss">
.down {
    width: 234px;
    height: 32px;
    border-radius: 4px;
    border: 1px solid #dbdbdb;
    line-height: 32px;
    padding-left: 5px;
    box-sizing: border-box;
    position: relative;
}

.list {
    width:100%;
    height: 150px;
    border-radius: 4px;
    overflow-y:scroll;
    position:absolute;
    top: 33px;
    z-index: 8;
    background: #fff;
    left: 0;

}

.li{
    width:calc(100% - 1px);
    height: 32px;
    border-radius: 4px;
    border: 1px solid #dbdbdb;
    line-height: 32px;
    padding-left: 5px;
    cursor: pointer;
    box-sizing: border-box;
}
.li:hover{
    background: rgb(244, 228, 228);
}
</style>