<template>
  <div class="box">
    <left :left="left">
      <div class="left_name">{{listLeft.album_name}}</div>
      <div class="left_name">{{listLeft.album_desc}}</div>
      <div class="left_name">{{listLeft.album_author}}</div>
      <div class="left_name">{{listLeft.album_issue_date}}</div>
      <div class="left_name">
       <div v-for="item in  arr"> {{item}}</div>
      </div>
    </left>
    <addindex  @addAlbumList="addAlbumList"></addindex>
  </div>
</template>
  
<script>



import left from '../../components/common/index/left.vue'
import addindex from './components/addindex.vue'
export default {
  name: '',
  computed: {


  },

  data() {
    return {
      left: {
        isbg: "#fa7199"
      },

      listLeft:'',
      arr:""
    }
  },
  components: {
    left,
    addindex
  },
  watch: {


  },
  created() {

  },

  mounted() {


  },
  methods: {
    addAlbumList(item){
      console.log(item,44);
      this.listLeft = item
      this.left.isbg =item.album_colors

     console.log((item.album_tag),11111);
    }
  },
}
</script>
  
  
<style scoped lang="scss">
.box {
  margin-left: 213px;
  margin-top: 60px;
}
.left_name{
  margin-top: 8px;
}
</style>