<template>
    <div>
        <div class="add_album" v-if="all.isHide">
            <div class="add_album_box">
                <div class="add_album_son">
                    <div class="add_album_title">
                        <span>创建专辑</span>
                    </div>
                    <div class="po">
                        <div class="name_span">专辑名字</div>
                        <div class="add_album_input">
                            <input class="add_album_pt" v-model="albumName" placeholder="专辑名字" type="text">
                        </div>

                        <div class="name_span">专辑作者</div>
                        <div class="add_album_input">
                            <input class="add_album_pt" v-model="albumAuthor" placeholder="专辑作者" type="text">
                        </div>

                        <div class="name_span">专辑标签</div>
                        <div class="add_album_input">
                            <input class="add_album_pt" v-model="albumTag" placeholder="专辑标签" type="text">
                        </div>
                        <div class="name_span">声波颜色/分类/专辑图片</div>
                        <div class="add_selectcolor">
                            <div class="add_flew">
                                <div class="le1">
                                    <div class="name_span">声波颜色</div>
                                    <selectColor ref="canvasColor" @rgbStr="rgbStr"></selectColor>
                                </div>
                                <div class="le2">
                                    <div class="name_span">分类</div>
                                    <div class="fl">
                                        <albumDown @selectDown="selectDown"></albumDown>
                                    </div>
                                </div>
                                <div class="le3">
                                    <div class="name_span">专辑图片</div>
                                    <div class="fl">
                                        <!-- <input type="file" class="aimg" id="aimg" @change="changeImg()"> -->
                                        <ImgCutter
                                            @cutDown="cutDown" 
                                            :WatermarkText="WatermarkText"
                                            :rate="rate"
                                        >
                                            <button slot="open">选择图片</button>
                                        </ImgCutter>
                                        <div>
                                            <img :src="image" alt="">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="name_span">专辑介绍</div>
                        <div class="add_album_input">
                            <textarea class="textarea_box" v-model="albumDesc" placeholder="专辑介绍"></textarea>
                        </div>
                    </div>

                    <div class="foot_bot">
                        <div class="foot_bot_l" @click="all.isHide = false">取消</div>
                        <div class="foot_bot_r" @click="btnTab()">确定</div>
                    </div>
                </div>
            </div>
        </div>
        <sideslip :sideslip="sideslip"></sideslip>
        <loading :loading="loading"></loading>
        <tips :tips="tips"></tips>
        
    </div>
</template>

<script>
import selectColor from '../components/selectColor.vue'
import albumDown from './albumDown.vue'
import sideslip from '../../../components/common/tips.vue'
import { timeDay } from '../../../components/js'
import api from '../../../api/album'
import ImgCutter from 'vue-img-cutter'
import loading from '../../../components/common/loading.vue'
import tips from '../../../components/common/tips.vue'


export default {
    name: 'index',
    props: {
        addAlbum: {
            type: Object,
            default: () => {
                return {
                    isHide: false,
                    albumName: "",
                    albumAuthor: "",
                    albumTag: "",
                    albumDesc: "",
                    cancelCallback: () => { },  //
                    determineCallback: () => { },  //
                }
            }
        },
    },
    data() {
        return {
            color: 'rgb(1,1,1)',
            isHide: false,
            albumName: "",
            albumAuthor: "",
            albumTag: "",
            albumDesc: "",
            rgbStrVal: "",
            selectDownVal: "",
            picFile: "",
            picFileData: "",
            cancelCallback: () => { },  //
            determineCallback: () => { },  //
            sideslip: {
                text: "",
                isShow: false,
                dataTime: 1
            },
            WatermarkText:"haitundj.com",
            rate:"1:1" , // 裁剪比例
            image:"",
            loading: {
                isShow: false
            },
            tips:{
                text:"",            
				dataTime: 1,
				isShow:false,
            },

        }
    },
    components: {
        selectColor,
        albumDown,
        sideslip,
        ImgCutter,
        loading,
        tips
    },
    computed: {
        all() {
            let Obj = this.addAlbum
            if (!Obj.isHide) {
                Obj.isHide = this.isHide
            }
            if (!Obj.albumName) {
                Obj.albumName = this.albumName
            }
            if (!Obj.albumAuthor) {
                Obj.albumAuthor = this.albumAuthor
            }
            if (!Obj.albumTag) {
                Obj.albumTag = this.albumTag
            }
            if (!Obj.albumDesc) {
                Obj.albumDesc = this.albumDesc
            }
            if (!Obj.cancelCallback) {
                Obj.cancelCallback = this.cancelCallback
            }
            if (!Obj.determineCallback) {
                Obj.determineCallback = this.determineCallback
            }
            return Obj
        }
    },
    mounted() {

    },
    methods: {
 
        rgbStr(val) {
            this.rgbStrVal = val
        },
        selectDown(val) {
            this.selectDownVal = val.id
        },

        // changeImg() {
        //     var imgDom = document.getElementById("aimg");
        //     var file = imgDom.files[0];
        //     var data = new FormData();
        //     data.append("images", file);
        //     api.upload(data).then((res) => {
        //         if (res.data.code == 200) {
        //             this.picFileData = res.data.result.filePath
        //         }
        //     })
        // },
        cutDown(event) {
            this.loading.isShow = true
            var data = new FormData();
            data.append("images", event.file);
            console.log(event.dataURL);
            this.image = event.dataURL
            api.upload(data).then((res) => {
                if (res.data.code == 200) {
                    this.picFileData = res.data.result.filePath
                    this.loading.isShow = false
                }else{
                    this.tips.isShow = true
                    this.tips.text =res.data.msg
                }
            })
        },
        btnTab() {
            var obj = {}
            if (!this.albumName) {
                this.sideslip.isShow = true
                this.sideslip.text = '专辑名字'
            } else if (!this.albumAuthor) {
                this.sideslip.isShow = true
                this.sideslip.text = '专辑作者'
            } else if (!this.albumTag) {
                this.sideslip.isShow = true
                this.sideslip.text = '专辑标签'
            } else if (!this.rgbStrVal) {
                this.sideslip.isShow = true
                this.sideslip.text = '声波颜色'
            } else if (!this.selectDownVal) {
                this.sideslip.isShow = true
                this.sideslip.text = '分类'
            } else if (!this.picFileData) {
                this.sideslip.isShow = true
                this.sideslip.text = '专辑图片'
            } else if (!this.albumDesc) {
                this.sideslip.isShow = true
                this.sideslip.text = '专辑介绍'
            } else {
                obj.album_name = this.albumName
                obj.album_desc = this.albumDesc
                obj.album_author = this.albumAuthor
                obj.album_issue_date = timeDay(Number(new Date()))
                obj.album_cover = String(this.picFileData)
                obj.album_colors = String(this.rgbStrVal)
                obj.album_cate_id = String(this.selectDownVal)
                // obj.album_tag = JSON.stringify([this.albumTag])
                obj.album_tag = String([(this.albumTag)])
                this.loading.isShow = true
                api.album(obj).then((res) => {
                    if (res.data.code == 200) {
                        this.all.determineCallback(obj)
                        this.all.isHide = false
                        this.loading.isShow = false
                        this.nullFun()
                    }else{
                        this.tips.isShow = true
                        this.tips.text =res.data.msg
                    }
                })
            }

        },
        nullFun(){
            this.albumName=''
            this.albumDesc=''
            this.albumAuthor=''
            this.picFileData=''
            this.rgbStrVal=''
            this.selectDownVal=''
            this.albumTag=''
        }

    }
}
</script>
<style scoped>
.add_album_title {
    text-align: center;
    font-size: 22px;
    margin-bottom: 26px;
}

.add_album {
    height: 100%;
    background: rgba(0, 0, 0, .3);
    position: fixed;
    top: 60px;
    left: 210px;
    bottom: 0;
    right: 0;
    z-index: 5;
}

.add_album_box {
    width: 800px;
    min-height: 200px;
    background: #fff;
    margin: 30px auto;
    padding: 12px;
    box-sizing: border-box;
    border-radius: 6px;
    position: relative;
    padding-bottom: 90px;
}

.add_album_son {
    width: 100%;
    max-height: 700px;
    overflow: scroll;
}




.add_album_input {
    width: 100%;
}

.add_album_pt {
    width: 100%;
    height: 32px;
    border: 1px solid #dbdbdb;
    border-radius: 4px;
    cursor: pointer;
    padding-left: 7px;
    box-sizing: border-box;
}

.textarea_box {
    width: 100%;
    height: 70px;
    padding: 5px;
    box-sizing: border-box;
    resize: none;
    background: #fbf8f8;
    cursor: pointer;
    border: 1px solid #dbdbdb;
    border-radius: 4px;
}

.foot_bot {
    width: 400px;
    height: 40px;
    position: absolute;
    bottom: 20px;
    line-height: 40px;
    left: 50%;
    margin-left: -200px;
    display: flex;
}

.foot_bot_l {
    flex: 1;
    height: 40px;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.6);
    color: #333;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 12px;
}

.foot_bot_r {
    flex: 1;
    height: 40px;
    background: #fb7299;
    color: #fff;
    text-align: center;
    border-radius: 4px;
    cursor: pointer;
    border: 1px solid #fb7299;
    margin-left: 12px;
}

.add_selectcolor {
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
}

.add_flew {
    display: flex;
    margin-top: 6px;
    border: 1px solid #dbdbdb;
    border-radius: 4px;
}

.le1 {
    flex: 1;
    padding: 12px 0 12px 12px;
    box-sizing: border-box;
    border-right: 1px solid #dbdbdb;
}

.le2 {
    flex: 1;
    padding: 12px 0 12px 12px;
    box-sizing: border-box;
    border-right: 1px solid #dbdbdb;
}

.le3 {
    flex: 1;
    padding: 12px 0 12px 12px;
    box-sizing: border-box;
    border-radius: 4px;
}

.fl {

    margin-top: 12px;
}

.name_span {
    margin-top: 12px;
}
</style>
