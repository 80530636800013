<template>
    <div>
        <!-- 添加歌曲-->
        <div class="add_album" v-if="all.isHide">
            <div class="add_album_box">
                <div class="add_album_son">
                    <div class="add_album_title">
                        <span>添加歌曲 {{ all.albumId }}</span>
                    </div>
                    <div>
                        <div class="add_flew1">
                            <div class="le4">
                                <div class="name_span">歌曲名字</div>
                                <div>
                                    <input class="add_album_pt" v-model="songName" type="text">
                                </div>
                            </div>

                            <div class="le5">
                                <div class="name_span">选择分类</div>
                                <div>
                                    <albumDown @selectDown="selectDown"></albumDown>
                                </div>
                            </div>
                        </div>

                        <div class="add_flew1">
                            <div class="le4">
                                <div class="name_span">歌曲作者</div>
                                <div>
                                    <input class="add_album_pt" v-model="songAuthor" type="text">
                                </div>
                            </div>

                            <div class="le5">
                                <div class="name_span">歌曲标签</div>
                                <div>
                                    <input class="add_album_pt" v-model="songTag" type="text">
                                </div>
                            </div>
                        </div>


                        <div class="add_album_input">
                            <div class="list_l">
                                <div class="name_span">声波颜色</div>
                                <div class="add_selectcolor">
                                    <div class="add_flew">
                                        <div class="le1">
                                            <div class="name_span">声波颜色</div>
                                            <selectColor ref="canvasColor" @rgbStr="rgbStr"></selectColor>
                                        </div>
                                        <div class="le2">
                                            <div class="name_span">歌曲封面</div>
                                            <div class="fl">
                                                <!-- <input type="file" class="aimg" id="aimg1" @change="changeImg(1)"> -->
                                                <ImgCutter
                                                    @cutDown="cutDown" 
                                                    :WatermarkText="WatermarkText"
                                                    :rate="rate"
                                                >
                                                    <button slot="open">选择图片</button>
                                                </ImgCutter>
                                                <img :src="image" alt="">
                                            </div>
                                        </div>
                                        <div class="le3">
                                            <div class="name_span">歌曲资源</div>
                                            <div class="fl">
                                                <input type="file" class="aimg" id="aimg2" @change="changeImg(2)">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="name_span">歌曲介绍</div>
                            <div class="add_album_input">
                                <textarea class="textarea_box" v-model="songDesc" placeholder="歌曲介绍"></textarea>
                            </div>
                            <div style="clear: both;"></div>
                        </div>
                    </div>

                    <div class="foot_bot">
                        <div class="foot_bot_l" @click="all.isHide = false">取消</div>
                        <div class="foot_bot_r" @click="btnTab()">确定</div>
                    </div>
                </div>
            </div>
        </div>
        <sideslip :sideslip="sideslip"></sideslip>
        <loading :loading="loading"></loading>
        <tips :tips="tips"></tips>
    </div>
</template>

<script>
import selectColor from './selectColor.vue'
import api from '../../../api/album'
import { timeDay } from '../../../components/js'
import albumDown from './albumDown.vue'
import sideslip from '../../../components/common/tips.vue'
import ImgCutter from 'vue-img-cutter'
import loading from '../../../components/common/loading.vue'
import tips from '../../../components/common/tips.vue'
export default {
    name: 'index',
    props: {
        addAlbumList: {
            type: Object,
            default: () => {
                return {
                    isHide: false,
                    songName: "",
                    songDesc: "",
                    songAuthor: "",
                    songTag: "",
                    albumId: "",
                    songDesc: "",
                    cancelCallback: () => { },  //
                    determineCallback: () => { },  //
                }
            }
        },
    },
    data() {
        return {
            isHide: false,
            songName: "",
            songDesc: "",
            songAuthor: "",
            songTag: "",
            albumId: "",
            songDesc: "",
            cancelCallback: () => { },  //
            determineCallback: () => { },  //

            rgbStrVal: "",
            selectDownVal: "",
            sideslip: {
                text: "",
                isShow: false,
                dataTime: 1
            },
            songCover: "",
            songSource: "",
            WatermarkText:"haitundj.com",
            rate:"1:1" , // 裁剪比例
            image:"",
            loading: {
                isShow: false
            },
            tips:{
                text:"",            
				dataTime: 1,
				isShow:false,
            },

        }
    },
    components: {
        selectColor,
        albumDown,
        sideslip,
        ImgCutter,
        loading,
        tips
    },
    computed: {
        all() {
            let Obj = this.addAlbumList
            if (!Obj.isHide) {
                Obj.isHide = this.isHide
            }
            if (!Obj.songName) {
                Obj.songName = this.songName
            }
            if (!Obj.songDesc) {
                Obj.songDesc = this.songDesc
            }
            if (!Obj.albumId) {
                Obj.albumId = this.albumId
            }
            if (!Obj.songAuthor) {
                Obj.songAuthor = this.songAuthor
            }
            if (!Obj.songTag) {
                Obj.songTag = this.songTag
            }
            if (!Obj.cancelCallback) {
                Obj.cancelCallback = this.cancelCallback
            }
            if (!Obj.determineCallback) {
                Obj.determineCallback = this.determineCallback
            }
            return Obj
        }
    },
    mounted() {

    },
    methods: {
        nullFun(){
            this.songName=""
            this.songDesc=""
            this.songAuthor=""
            this.songTag=""
            this.albumId=""
            this.selectDownVal=""
            this.songSource =""
            this.songCover = ''
            this.rgbStrVal=''
        },
        changeImg(val) {
            this.loading.isShow = true
            var imgDom = document.getElementById(`aimg${val}`);
            var file = imgDom.files[0];
            var data = new FormData();
            if (val == 1) {
                // data.append("images", file);
                // api.upload(data).then((res) => {
                //     if (res.data.code == 200) {
                //         this.songCover = res.data.result.filePath
                //     }
                // })
            } else {
                data.append("file", file);
                var fileType= "AUDIO"
                api.uploadFile(data,fileType).then((res) => {
                    if (res.data.code == 200) {
                        this.songSource = res.data.result.filePath
                        this.loading.isShow = false
                    }else{
                        this.tips.isShow = true
                        this.tips.text =res.data.msg
                    }
                })
            }
        },

        cutDown(event) {
            this.loading.isShow = true
            var data = new FormData();
            data.append("images", event.file);
            this.image = event.dataURL
            api.upload(data).then((res) => {
                if (res.data.code == 200) {
                    this.songCover = res.data.result.filePath
                    this.loading.isShow = false
                }else{
                    this.tips.isShow = true
                    this.tips.text =res.data.msg
                }
            })
        },
        rgbStr(val) {
            this.rgbStrVal = val
        },
        selectDown(val) {
            this.selectDownVal = val.id
        },
        btnTab() {
            
            var obj = {}
            if (!this.songName) {
                this.sideslip.isShow = true
                this.sideslip.text = '歌曲名字'
            } else if (!this.songAuthor) {
                this.sideslip.isShow = true
                this.sideslip.text = '歌曲作者'
            } else if (!this.selectDownVal) {
                this.sideslip.isShow = true
                this.sideslip.text = '选择分类'
            } else if (!this.songTag) {
                this.sideslip.isShow = true
                this.sideslip.text = '歌曲标签'
            } else if (!this.rgbStrVal) {
                this.sideslip.isShow = true
                this.sideslip.text = '声波颜色'
            } else if (!this.songCover) {
                this.sideslip.isShow = true
                this.sideslip.text = '歌曲封面'
            } else if (!this.songSource) {
                this.sideslip.isShow = true
                this.sideslip.text = '歌曲资源'
            } else if (!this.songDesc) {
                this.sideslip.isShow = true
                this.sideslip.text = '歌曲介绍'
            } else {
                // 声波
                function random(min, max) {
                    return Math.floor(Math.random() * (max - min)) + min;
                };
                var a = []
                for (var i = 1; i < 1800; i++) {
                    a.push(random(-0.9, 3))
                }
                var obj = {
                    "song_name": this.songName,
                    "song_author": this.songAuthor,
                    "song_issue_date": timeDay(Number(new Date())),
                    "song_album_id": this.all.albumId,
                    "song_cover": String(this.songCover),
                    "song_colors": String(this.rgbStrVal),
                    "song_tag": JSON.stringify([this.songTag]),
                    "song_source": String(this.songSource),
                    "song_cate_id": String(this.selectDownVal),
                    "song_sound_wave": String(a),  // 声波
                    "song_desc": String(this.songDesc),
                }
                this.loading.isShow = true
                api.songs([obj]).then((res) => {
                    if (res.data.code == 200) {
                        // console.log(res.data, "成功了")
                        this.all.isHide = false
                        this.loading.isShow = false
                        this.nullFun()
                    }else{
                        this.tips.isShow = true
                        this.tips.text =res.data.msg
                    }
                })
            }
        }
    }
}
</script>
<style scoped>
.add_album_title {
    text-align: center;
    font-size: 22px;
    margin-bottom: 26px;
}

.add_album {
    height: 100%;
    background: rgba(0, 0, 0, .3);
    position: fixed;
    top: 60px;
    left: 210px;
    bottom: 0;
    right: 0;
    z-index: 5;
}

.add_album_box {
    width: 800px;
    min-height: 200px;
    background: #fff;
    margin: 30px auto;
    padding: 12px;
    box-sizing: border-box;
    border-radius: 6px;
    position: relative;
    padding-bottom: 100px;
}

.add_album_son {
    width: 100%;
    max-height: 700px;
    overflow: scroll;
}

.bottom {
    width: 59px;
    background: red;
    display: inline-block;
    margin-right: 8px;
    padding: 2px 5px;
    box-sizing: border-box;
    color: #fff;
    font-size: 10px;
    border-radius: 4px;
}



.add_album_input {
    width: 100%;
}

.textarea_box {
    width: 100%;
    height: 70px;
    padding: 5px;
    box-sizing: border-box;
    resize: none;
    background: #fbf8f8;
    cursor: pointer;
    border: 1px solid #dbdbdb;
    border-radius: 4px;
}

.add_album_pt {
    width: 100%;
    height: 32px;
    border: 1px solid #dbdbdb;
    border-radius: 4px;
    cursor: pointer;
    padding-left: 7px;
    box-sizing: border-box;
}



.foot_bot {
    width: 400px;
    height: 40px;
    position: absolute;
    bottom: 20px;
    line-height: 40px;
    left: 50%;
    margin-left: -200px;
    display: flex;
}

.foot_bot_l {
    flex: 1;
    height: 40px;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.6);
    color: #333;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 12px;
}

.foot_bot_r {
    flex: 1;
    height: 40px;
    background: #fb7299;
    color: #fff;
    text-align: center;
    border-radius: 4px;
    cursor: pointer;
    border: 1px solid #fb7299;
    margin-left: 12px;
}

.add_selectcolor {
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
}

.add_flew {
    display: flex;
    margin-top: 6px;
    border: 1px solid #dbdbdb;
    border-radius: 4px;
}

.le1 {
    flex: 1;
    padding: 12px 0 12px 12px;
    box-sizing: border-box;
    border-right: 1px solid #dbdbdb;
}

.le2 {
    flex: 1;
    padding: 12px 0 12px 12px;
    box-sizing: border-box;
    border-right: 1px solid #dbdbdb;
}

.le3 {
    flex: 1;
    padding: 12px 0 12px 12px;
    box-sizing: border-box;
    border-radius: 4px;
}

.add_flew1 {
    display: flex;
}

.le4 {
    flex: 1;
    padding-right: 12px;
    box-sizing: border-box;
}

.le5 {
    flex: 1;
    padding-left: 12px;
    box-sizing: border-box;
}

.fl {

    margin-top: 12px;
}

.name_span {
    margin-top: 12px;
}

/deep/ .down {
    width: 376px;
}
</style>
