<template>
    <div>
        <!-- 添加歌曲-->
        <div class="add_album" v-if="all.isHide">
            <div class="add_album_box">
                <div class="add_album_son">
                    <div class="add_album_title">
                        <span>添加歌曲</span>
                    </div>
                    <div class="po">
                        <div>
							<div class="list_ov">
								<div class="list_li0">歌曲封面</div>
								<div class="list_li1">歌曲作者</div>
								<div class="list_li2">歌曲名字</div>
								<div class="list_li3">歌曲简介</div>
								<div class="list_li4">歌曲标签</div>
								<div class="list_li5">上传时间</div>
							</div>
                            <div class="gd">
                                <div class="list_ov " v-for="(item,index) in all.musicList" :key="index">
                                    <div class="list_li0">
                                        <imgIng v-if="item&&item.songCover" class="list_li_img" :src="(item&&item.songCover)" alt=""></imgIng>
                                    </div>
                                    <div class="list_li1">{{item.song_author}}</div>
                                    <div class="list_li2">{{item.song_name}}</div>
                                    <div class="list_li3">{{item.song_desc}}</div>
                                    <div class="list_li4">{{item.song_tag}}</div>
                                    <div class="list_li5">{{item.song_issue_date}}</div>
                                </div>
                                <div class="no" v-if="all.musicList.length<=0">
                                    暂无歌曲 ～ 请上传
                                </div>
                            </div>
					
						</div>
					</div>

                    <div class="foot_bot">
                        <div class="foot_bot_l" @click="all.isHide = false">取消</div>
                        <!-- <div class="foot_bot_r" @click="all.determineCallback()">确定</div> -->
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import selectColor from '../components/selectColor.vue'
import api from '../../../api/album'
import imgIng from '../../../components/common/imgIng.vue'
export default {
    name: 'index',
    props: {
        addAlbumLook: {
            type: Object,
            default: () => {
                return {
                    isHide: false,
                    musicList:[],

                    cancelCallback: () => { },  //
                    determineCallback: () => { },  //

                }
            }
        },
    },
    data() {
        return {
            isHide: false,
            musicList:[],
            cancelCallback: () => { },  //
            determineCallback: () => { },  //
        }
    },
    components: {
        selectColor,
        imgIng
    },
    computed: {
        all() {
            let Obj = this.addAlbumLook
            if (!Obj.isHide) {
                Obj.isHide = this.isHide
            }
            if (!Obj.musicList) {
                Obj.musicList = this.musicList
            }
            if (!Obj.cancelCallback) {
                Obj.cancelCallback = this.cancelCallback
            }
            if (!Obj.determineCallback) {
                Obj.determineCallback = this.determineCallback
            }
            return Obj
        }
    },
    mounted() {
 
    },
    methods: {
   


    },
}
</script>
<style scoped>



.add_album {
    height: 100%;
    background: rgba(0, 0, 0, .3);
    position: fixed;
    top: 60px;
    left: 210px;
    bottom: 0;
    right: 0;
    z-index: 5;
}


.add_album_box {
    width: 800px;
    height: 760px;
    background: #fff;
    margin: 30px auto;
    padding: 12px;
    box-sizing: border-box;
    border-radius: 6px;
    position: relative;
    padding-bottom: 0px;
}


.add_album_son {
    width: 100%;
 
}

.bottom {
    width: 59px;
    background: red;
    display: inline-block;
    margin-right: 8px;
    padding: 2px 5px;
    box-sizing: border-box;
    color: #fff;
    font-size: 10px;
    border-radius: 4px;
}



.foot_bot {
    width: 400px;
    height: 40px;
    position: absolute;
    bottom: 20px;
    line-height: 40px;
    left: 50%;
    margin-left: -200px;
    display: flex;
}

.foot_bot_l {
    flex: 1;
    height: 40px;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.6);
    color: #333;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 12px;
}

.foot_bot_r {
    flex: 1;
    height: 40px;
    background: #fb7299;
    color: #fff;
    text-align: center;
    border-radius: 4px;
    cursor: pointer;
    border: 1px solid #fb7299;
    margin-left: 12px;
}






.list_ov {
    width: 100%;
    height: 34px;
    line-height: 34px;
    border-bottom: 1px dashed #e1e1e1;
    overflow: hidden;
}
.gd{
    overflow: hidden;
    height: 620px;
    background: rgb(245 245 245 / 39%);
    overflow: scroll;
}

.list_li0 {
    width: 34px;
    height: 34px;
    float: left;
}

.list_li_img {
    width: 100%;
}

.list_li1 {
    width: 100px;
    float: left;
    margin-left: 12px;
}

.list_li2 {
    width: 130px;
    float: left;
}

.list_li3 {
    width: 200px;
    float: left;
}

.list_li4 {
    width: 200px;
    float: left;
}

.list_li5 {
    width: 100px;
    float: left;
}

.no {
    margin-top: 30px;
    font-size: 20px;
    text-align: center;
}
</style>
